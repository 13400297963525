import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import loadable from '@loadable/component';
import _ from 'lodash';

import {
  isCompareEnabledSelector,
  isWishListEnabledSelector,
  reactiveTemplateConfigSelector,
} from 'common/selectors/theme';

import { getLogoPath, setToObject } from 'common/utils';

import { getLogoSelector } from 'common/selectors';
import { getPageDataSelector } from 'common/selectors/page';
import { getMenuSelector } from 'common/selectors/menu';

const NavigationMenuVariant = loadable((props) =>
  import(`./menu/${props.blockVariant}`)
);

const Navigation = ({
  menu = [],
  logo,
  dataCategorical,
  blockSettings,
  toggleNav,
  toggleSearch,
  navIsHidden,
  theme,
}) => {
  const navigationData = React.useMemo(() => ({
    menu: menu,
    logo: logo,
    activeItems: _.head(dataCategorical),
    settings: {
      isCompareEnabled: _.get(blockSettings, 'isCompareEnabled', true),
      isWishListEnabled: _.get(blockSettings, 'isWishListEnabled', true),
      isMobileTopbarEnabled: _.get(
        blockSettings,
        'isMobileTopbarEnabled',
        true
      ),
    },
    functions: {
      toggleNav: toggleNav,
      toggleSearch: toggleSearch,
    },
    blockVariant:
      'NavigationMenuVariant' + _.get(blockSettings, 'blockVariant', 1),
  }), []);
  return (
    <NavigationMenuVariant
      navIsHidden={navIsHidden}
      theme={theme}
      {...navigationData}
    />
  );
};

const blockSettings = {};
const makeMapStateToProps = () => {
  const getMenu = getMenuSelector();
  const getPageData = getPageDataSelector();
  const getLogo = getLogoSelector();
  const isWishListEnabled = isWishListEnabledSelector();
  const isCompareEnabled = isCompareEnabledSelector();
  const reactiveTemplateConfig = reactiveTemplateConfigSelector(
    'NavigationVariant',
    false
  );
  return (state, props) => {
    return {
      logo: getLogoPath(getLogo(state, props), '120x40.png'),
      menu: getMenu(state, props),
      dataCategorical: _.get(
        getPageData(state, props),
        'breadcrumbs.categorical',
        null
      ),
      blockSettings: setToObject(blockSettings, {
        isCompareEnabled: isCompareEnabled(state, props),
        isWishListEnabled: isWishListEnabled(state, props),
        isMobileTopbarEnabled: true,
        blockVariant: reactiveTemplateConfig(state, props)
      })
    };
  };
};

const NavigationConnected = connect(makeMapStateToProps)(Navigation);

export default withTheme(NavigationConnected);
